import type { FC } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Box, Flex, xcss } from '@atlaskit/primitives';

import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { ReactionsProvider } from '@confluence/comment-context';
import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ReactionsSSRLoader } from '@confluence/reactions/entry-points/ReactionsSSRLoader';
import { useSessionData } from '@confluence/session-data';

import { ReactionsLoaderBackground as DelayedReactions } from './ReactionsLoader';
import type { ReactionLocation } from './types';
import { ReactionContainerType } from './types';
import { useReactionsQueryParams } from './hooks/useReactionsQueryParams';

const ViewPageReactionsContainerCommonStyles = xcss({
	display: 'flex',
	clear: 'both',
	overflow: 'visible',
	maxWidth: 'unset',
	margin: 'unset',
	marginBottom: 'space.025',
});

type ViewPageReactionsProps = {
	contentId: string;
	contentType?: string | null;
	spaceId?: string | null;
	contentSubType?: string | null;
};

const ReactionsComponent: FC<ViewPageReactionsProps> = ({
	contentId,
	contentType,
	spaceId,
	contentSubType,
}) => {
	const [state] = useContentState();
	const { focusedPageReactionsId } = useReactionsQueryParams();
	const { edition } = useSessionData();
	const [reactionsRef, reactionsSectionInView] = useInView({
		rootMargin: '-100px 0px 0px 0px',
	});

	const displayReactions = () => {
		if (contentType && spaceId) {
			// We want to load in the background ONLY if we have a query param, otherwise we can preload the query
			const ReactionsLoader = Boolean(focusedPageReactionsId)
				? DelayedReactions
				: ReactionsSSRLoader;

			return (
				<Flex>
					<ReactionsLoader
						contentId={contentId}
						location={contentType as ReactionLocation}
						containerId={spaceId}
						containerType={ReactionContainerType.SPACE}
						contentSubType={contentSubType}
						summaryViewEnabled
						animateNoReactionsText
						customAnalyticsAttributes={{
							edition,
						}}
						summaryViewPlacement="bottom-start"
						isSubtle
					/>
				</Flex>
			);
		} else {
			return <ReactionsPlaceholder />;
		}
	};

	return (
		<Box
			ref={reactionsRef}
			testId="view-page-byline-reactions"
			xcss={[ViewPageReactionsContainerCommonStyles]}
		>
			<ReactionsProvider contentId={contentId}>
				{!process.env.REACT_SSR && reactionsSectionInView && state?.isContentReady ? (
					displayReactions()
				) : (
					<ReactionsPlaceholder />
				)}
			</ReactionsProvider>
		</Box>
	);
};

export const ViewPageBylineReactions: FC<ViewPageReactionsProps> = (props) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.NOTIFICATIONS}>
			<ReactionsComponent {...props} />
		</TransparentErrorBoundary>
	);
};
