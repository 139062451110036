import React, { Fragment, useEffect, type FC } from 'react';
import { useQuery } from 'react-apollo';

import { Flex, xcss } from '@atlaskit/primitives';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';

import { ContentUnifiedQuery, ContentBlogUnifiedQuery } from '@confluence/content-unified-query';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { StickyBanner } from '@confluence/content-header';
import { useContentType } from '@confluence/page-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import {
	useShowConvertPageToFolder,
	BANNER_HEIGHT,
} from '@confluence/folder-conversion/entry-points/useShowConvertPageToFolder';
import {
	useViewPageDistinctionBannerStore,
	VIEW_PAGE_DISTINCTION_BANNER_HEIGHT,
} from '@confluence/live-pages-features/entry-points/ViewPageDistinctionBanner';
import { HighlightActionsProviderForNav4 } from '@confluence/highlight-actions/entry-points/HighlightActionsProvider';
import { useObjectSidebar } from '@confluence/object-sidebar-api';
import {
	ObjectSidebarPanel,
	ObjectSidebarPushPlaceholder,
} from '@confluence/object-sidebar-components';
import { fg } from '@confluence/feature-gating';

import { ContentScreenComponent } from './ContentScreenComponent';
import { ContentContainerComponent } from './ContentContainerComponent';

const EMPTY_CONTENT_SCREEN_STYLES = {};
const EMPTY_LOOK_AND_FEEL = {};

const contentWrapperStyles = xcss({
	display: 'block',
	flex: '2',
});

type ContentContainerProps = {
	contentId: string;
	fetchTheme?: boolean;
	children: React.ReactNode;
	isScreenContentView?: boolean;
	isContainerContentView?: boolean;
	spaceKey?: string;
	errorAttribution: string;
	contentScreenStyles?: any;
	stickyHeader?: React.ReactNode;
	versionOverride?: number | null;
};

export const ContentContainer: FC<ContentContainerProps> = ({
	contentId,
	fetchTheme = false,
	children,
	isScreenContentView = false,
	isContainerContentView = false,
	spaceKey,
	errorAttribution = Attribution.BACKBONE as string,
	contentScreenStyles = EMPTY_CONTENT_SCREEN_STYLES,
	stickyHeader = null,
	versionOverride = null,
}) => {
	const isLivePage = useIsCurrentPageLive();
	const [contentType] = useContentType();
	const [{ isObjectSidebarShown, behavior }, { hideObjectSidebar }] = useObjectSidebar();
	const pageMode = useGetPageMode();

	const { showBanner } = useShowConvertPageToFolder({ contentId, spaceKey: spaceKey || '' });
	const [{ isViewPageBannerVisible }] = useViewPageDistinctionBannerStore();

	let chooseContentUnifiedQuery = ContentUnifiedQuery;

	// Close the object sidebar when navigating to a new page
	useEffect(() => {
		hideObjectSidebar();
	}, [contentId, hideObjectSidebar]);

	if (contentType === 'blogpost') {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	const queryResult = useQuery(chooseContentUnifiedQuery, {
		context: { single: true },
		skip: !fetchTheme || !contentId,
		variables: {
			contentId,
			spaceKey,
			versionOverride,
			useNewContentTopper: true,
		},
	});

	if (queryResult.error) {
		return (
			<ErrorBoundary attribution={errorAttribution}>
				{children}
				<ErrorDisplay error={queryResult.error} />
			</ErrorBoundary>
		);
	} else {
		const theme = Boolean(queryResult?.data?.space?.theme);
		const lookAndFeel = queryResult?.data?.space?.lookAndFeel || EMPTY_LOOK_AND_FEEL;

		const childrenToRender =
			typeof children === 'function'
				? children({ isThemed: theme, isLoading: queryResult.loading })
				: children;

		return (
			<ErrorBoundary attribution={errorAttribution}>
				<ContentScreenComponent
					spaceKey={spaceKey}
					isThemed={theme}
					isLoading={queryResult.loading}
					lookAndFeel={lookAndFeel}
					isContentView={isScreenContentView}
					contentScreenStyles={contentScreenStyles}
					isLivePage={isLivePage}
				>
					{!theme && stickyHeader && <StickyBanner>{stickyHeader}</StickyBanner>}
					<ContentContainerComponent
						isThemed={theme}
						lookAndFeel={lookAndFeel}
						isContentView={isContainerContentView}
						isLoading={queryResult.loading}
						isLivePage={isLivePage}
						isObjectSidebarEnabled={fg('confluence_frontend_object_sidebar')}
						additionalPaddingTop={
							(showBanner ? BANNER_HEIGHT : 0) +
							(isViewPageBannerVisible ? VIEW_PAGE_DISTINCTION_BANNER_HEIGHT : 0)
						}
					>
						{fg('confluence_frontend_object_sidebar') ? (
							<Fragment>
								<Flex xcss={contentWrapperStyles}>{childrenToRender}</Flex>
								{isObjectSidebarShown && pageMode === PageMode.VIEW && (
									<Fragment>
										{behavior === 'push' && <ObjectSidebarPushPlaceholder />}
										<ObjectSidebarPanel />
									</Fragment>
								)}
							</Fragment>
						) : (
							childrenToRender
						)}
					</ContentContainerComponent>
					<HighlightActionsProviderForNav4 />
				</ContentScreenComponent>
			</ErrorBoundary>
		);
	}
};
