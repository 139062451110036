import React, { memo, type FC, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { Text } from '@atlaskit/primitives';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { ProfileLink } from '@confluence/profile';
import { Link } from '@confluence/route-manager';

import type { UserShape, Version } from './byLineTypes';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledVersionComment = styled.span({
	display: 'inline-block',
	marginLeft: token('space.075', '6px'),
	cursor: 'default',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '•',
		display: 'inline-block',
		color: token('color.text.subtle', 'grey'),
		padding: `0 ${token('space.100', '8px')} 0 0`,
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLastModified = styled.div({
	display: 'inline-block',
	'&::first-letter': {
		textTransform: 'capitalize',
	},
});

export type ByLineLastModifiedProps = {
	author: UserShape;
	version: Version;
	versionComment?: string;
	contentId: string;
};

export const ByLineLastModified: FC<ByLineLastModifiedProps> = memo(
	({ version, author, contentId, versionComment }) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		let lastUpdatedLine: ReactNode;

		if (version.number === 1 || version.contentTypeModified) {
			lastUpdatedLine = (
				<Text
					size="small"
					color="color.text.subtle"
					id="content-header.by-line.last.updated.version.1"
				>
					{version.friendlyWhen}
				</Text>
			);
		} else {
			const modifiedByAuthor = !author.isAnonymous && author.userId === version.by.userId;
			const currentVersion = version.number;
			const prevVersion = currentVersion - 1;
			const query = `pageId=${contentId}&selectedPageVersions=${prevVersion}&selectedPageVersions=${currentVersion}`;
			const style = {
				color: token('color.text.subtle', N500),
			};
			const changeLink = (
				<Link
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					href={`${CONTEXT_PATH}/pages/diffpagesbyversion.action?${query}`}
				>
					{version.friendlyWhen}
				</Link>
			);

			lastUpdatedLine = modifiedByAuthor ? (
				<FormattedMessage
					id="content-header.by-line.last.updated.self.expanded"
					defaultMessage="Last updated: {date}"
					description="Text to show Last updated label"
					values={{
						date: changeLink,
					}}
				/>
			) : (
				<FormattedMessage
					id="content-header.by-line.last.updated.expanded"
					defaultMessage="Last updated: {date} by {contributor}"
					description="Text to show Last updated Date by contributor label"
					values={{
						date: changeLink,
						contributor: (
							<ProfileLink
								userId={version.by.userId}
								fullName={version.by.fullName}
								isAnonymous={version.by.isAnonymous}
								referralSource="profilecard"
							/>
						),
					}}
				/>
			);

			if (versionComment) {
				const fireTooltipEvent = () => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							source: 'page',
							action: 'displayed',
							actionSubject: 'tooltip',
							actionSubjectId: 'publishComment',
						},
					}).fire();
				};

				lastUpdatedLine = (
					<Text>
						{lastUpdatedLine}
						<Tooltip tag="span" content={versionComment} onShow={fireTooltipEvent}>
							<StyledVersionComment>
								<FormattedMessage
									id="content-header.by-line.last.updated.version.comment.indicator"
									defaultMessage="Version comment"
									description="Text that indicates a comment was made the last time this page was published. Rolling over the text shows the comment."
								/>
							</StyledVersionComment>
						</Tooltip>
					</Text>
				);
			}
		}

		return (
			<StyledLastModified data-testid="page-last-modified">{lastUpdatedLine}</StyledLastModified>
		);
	},
);
